<template>
  <DashboardTemplate>
    <Layout class="pd-t-3">
      <div v-loading="loading">
        <div>
          <span
            @click="$router.push('/factor')"
            class="font-20 cr-pointer text-primary"
            ><i class="el-icon-arrow-left"></i> กลับหน้าแรก</span
          >
        </div>
        <div>
          <h3 class="font-30 mg-b-6 mg-t-3 text-center">
            {{ categoryName }}
          </h3>
          <p class="text-grey text-center font-20 mg-y-7">
            (กรุณาเลือกระดับเพื่อดูข้อมูล
            กดต่อไปเพื่อบันทึกคำตอบและทำแบบประเมินข้อถัดไป)
          </p>
        </div>
        <div class="text-center mg-t-4">
          <h4 class="font-20 mg-y-7 text-grey">
            <span class="text-primary">ข้อที่ {{ number }}</span> จาก
            {{ questionTotal }} ข้อ
          </h4>
          <div class="block mg-y-4">
            <el-progress
              :percentage="barPercen"
              :show-text="false"
              color="#07509C"
            ></el-progress>
          </div>
          <h3 class="font-20 mg-y-7 font-weight-bold">
            {{ questionName }}
          </h3>
        </div>
        <div class="mg-t-3 tab-level" style="min-height: 40vh">
          <div class="wizard row mg-b-3">
            <a
              class="no-select"
              v-for="(data, index) in levelData"
              :key="data.id"
              @click="(tabActive = data.level), selectChoice(index)"
              :class="tabActive == data.level ? 'progress-current' : ''"
            >
              <div class="badge">
                <div class="border"></div>
              </div>
              {{ data.name }}
            </a>
          </div>
          <div v-for="data in levelData" :key="data.id">
            <div v-if="data.level == tabActive" class="bordered rounded">
              <div class="pd-7 border-bottom text-center">
                <h2 class="mg-y-7">{{ data.name }}</h2>
              </div>
              <div
                class="editor-contents wangEditor-txt pd-x-2 pd-y-3"
                v-html="data.description"
              ></div>
            </div>
          </div>

          <!-- <el-tabs v-model="tabActive" @tab-click="selectChoice">
            <el-tab-pane
              :label="data.name"
              :name="data.name"
              v-for="data in levelData"
              :key="data.id"
            >
              <p>{{ data.name }}</p>

              <div class="editor-contents" v-html="data.description"></div>
             
            </el-tab-pane>
          </el-tabs> -->
        </div>
        <div class="is-flex js-between ai-center mg-t-3">
          <a class="font-22" @click="preLevel()"
            ><span v-if="number > 1">ย้อนกลับ</span>
          </a>
          <div class="text-center" v-if="choice">
            <el-button
              type="primary"
              class="wid220"
              @click="nextLevel()"
              :loading="loadingNext"
              >ต่อไป</el-button
            >
          </div>
        </div>
      </div>
    </Layout>
  </DashboardTemplate>
</template>
<script>
import DashboardTemplate from "@/template/DashboardTemplate";
import { HTTP } from "@/service/axios";
import Layout from "@/template/LayoutDashboardTemplate";
// import "codemirror/lib/codemirror.css";
// import "@toast-ui/editor/dist/toastui-editor-viewer.css";
// import { Viewer } from "@toast-ui/vue-editor";

export default {
  components: {
    DashboardTemplate,
    Layout,
    // viewer: Viewer,
  },
  props: {
    routeParams: {
      type: Object,
      default: null,
    },
  },
  computed: {
    progress() {
      let total = 0;
      console.log(typeof (this.number * 100) / (this.questionTotal + 1));
      if (typeof ((this.number * 100) / this.questionTotal) != NaN) {
        total = (this.number * 100) / (this.questionTotal + 1);
      }
      if (typeof ((this.number * 100) / this.questionTotal) != NaN) {
        return total;
      } else {
        return 0;
      }
    },
    token() {
      return this.$store.state.token;
    },
    category() {
      return this.categoryList.filter((filter) => filter.id == this.cate);
    },
    categoryName() {
      if (this.category.length > 0) {
        return this.category[0].name;
      } else {
        return "";
      }
    },
    questionTotal() {
      if (this.category.length > 0) {
        this.barPercen = this.getPercen(
          this.routeParams.number,
          this.category[0].question + 1
        );
        return this.category[0].question;
      } else {
        return 0;
      }
    },
  },
  watch: {
    routeParams() {
      this.loading = true;
      this.setParams();
      this.fetchChoice();
    },
  },
  data() {
    return {
      number: 1,
      choice: null,
      tabActive: "",
      levelData: [],
      assessment: "",
      cate: "",
      question: "",
      categoryList: [],
      questionName: "",
      loading: true,
      loadingNext: false,
      answerId: "",
      barPercen: 0,
    };
  },
  mounted() {
    this.setParams();
    this.fetchAsseement();
    this.fetchChoice();
  },
  methods: {
    getPercen(number = 0, total = 0) {
      let percen = 0;
      percen = (number * 100) / total;
      return Number(percen);
    },
    preLevel() {
      this.choice = null;
      this.tabActive = "";
      let number = Number(this.number) - 1;

      this.$router.push(
        `/level?assessment=${this.assessment}&cate=${this.cate}&question=${
          this.category[0].questionsId[number - 1].questionId
        }&number=${number}`
      );
    },
    selectChoice(e) {
      this.choice = this.levelData[e];
    },
    setParams() {
      if (this.routeParams != null) {
        if (typeof this.routeParams.assessment != "undefined") {
          this.assessment = this.routeParams.assessment;
        }

        if (typeof this.routeParams.cate != "undefined") {
          this.cate = this.routeParams.cate;
        }

        if (typeof this.routeParams.question != "undefined") {
          this.question = this.routeParams.question;
        }

        if (typeof this.routeParams.number != "undefined") {
          this.number = this.routeParams.number;
        }
      }
    },
    fetchAsseement ()
    {
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.get(`choose/asseement`)
        .then((res) => {
          //console.log(`choose/asseement`,res);
          if (res.data.success) {
            this.categoryList = res.data.obj.cate;
            this.fetchAnswered();
          }
        })
        .catch((e) => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("fetchAsseement error", e);
        });
    },
    fetchChoice() {
      let obj = {
        assessmentId: this.assessment,
        cateId: this.cate,
        questionId: this.question,
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post(`get/choice`, obj)
        .then((res) => {
          //console.log(`get/choice`,res);
          if (res.data.success) {
            this.questionName = res.data.obj.questionName;
            this.levelData = res.data.obj.choices;

            this.fetchAnswered();
          }
        })
        .catch((e) => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("fetchChoice error", e);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    fetchAnswered() {
      let obj = {
        assessmentId: this.assessment,
        cateId: this.cate,
        questionId: this.question,
      };
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post(`answered`, obj)
        .then((res) => {
          //console.log(`answered`, res);
          if (res.data.success) {
            this.choice = this.levelData[res.data.obj[0].level - 1];
            let filter = this.levelData.filter(
              (filter) => filter.id == res.data.obj[0].id
            );

            if (filter.length > 0) {
              this.tabActive = filter[0].level;
            }
          }
        })
        .catch((e) => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("fetchChoice error", e);
        });
    },
    nextLevel() {
      this.loadingNext = true;
      let obj = {
        assessmentId: this.assessment,
        cateId: this.cate,
        questionId: this.category[0].questionsId[this.number - 1].questionId,
        qAndCId: this.choice.qAndcId,
      };
      if (this.questionTotal == this.number) {
        // this.barPercen = this.getPercen(this.number + 1, this.questionTotal);
      }
      HTTP.defaults.headers.common["X-CSRF-TOKEN"] = this.$csrfToken;
      HTTP.defaults.headers.common.Authorization = `Bearer ${this.token}`;
      HTTP.post(`save`, obj)
        .then((res) => {
          if (res.data.success) {
            if (this.questionTotal == this.number) {
              this.$router.push("/factor");
            } else {
              this.choice = null;
              this.tabActive = "";
              let number = Number(this.number) + 1;

              this.$router.push(
                `/level?assessment=${this.assessment}&cate=${
                  this.cate
                }&question=${
                  this.category[0].questionsId[number - 1].questionId
                }&number=${number}`
              );
            }
          } else {
            this.alertFailError();
          }
        })
        .catch((e) => {
          if (e == "Error: Request failed with status code 401") {
            this.checkAuth();
          } else {
            if (e != "Error: timeout of 120000ms exceeded") {
              this.alertCatchError(e);
            }
          }
          console.log("saveChoice error", e);
        })
        .finally(() => {
          this.loadingNext = false;
        });
    },
  },
};
</script>
